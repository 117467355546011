/* eslint-disable react/no-array-index-key */
import { benefitsItems } from './config/benefits.config';
import { platformsItems } from './config/platforms.config';
import styles from './styles.module.scss';

const countOfDecorations = 10;

const arrayOfDecorations = Array.from({ length: countOfDecorations }).map(
  (_, idx) => idx + 1,
);

export const Hero = () => {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        {arrayOfDecorations.map((decorationId) => {
          const classes = [styles.decor, styles[`decor${decorationId}`]];

          return (
            <img
              aria-hidden
              key={decorationId}
              className={classes.join(' ')}
              loading="lazy"
              src={`/images/decoration/${decorationId}.png`}
              alt="Decoration"
            />
          );
        })}
        <div className={styles.content}>
          <img
            className={styles.logo}
            loading="lazy"
            src="/images/logo.svg"
            alt="Casino7"
          />
          <h1 className={styles.title}>
            Скачивайте приложение и играйте в любое время, в любом месте
          </h1>
          <ul className={styles.platforms}>
            {platformsItems.map((Icon, idx) => (
              <li className={styles.platformItem} key={idx}>
                <Icon />
              </li>
            ))}
          </ul>
          <ul className={styles.list}>
            {benefitsItems.map((benefit) => {
              const IconComponent = benefit.icon;

              return (
                <li className={styles.item} key={benefit.label}>
                  <IconComponent className={styles.icon} />
                  <span className={styles.label}>{benefit.label}</span>
                </li>
              );
            })}
          </ul>
          <a
            target="_blank"
            href="https://your-app.link/JOMH9"
            className={styles.btn}
            rel="noopener"
          >
            Скачать
          </a>
        </div>
      </div>
    </section>
  );
};
